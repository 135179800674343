<template lang="pug">
    v-card#edit-deal-date
        v-card-title.d-flex.justify-center {{$t('EDITDEALDATE.TITLE')}}
        v-card-text
            v-text-field.no-pointer-event(v-model='opptyData.name' :label=`$t('EDITDEALDATE.NAME')`, required='required' large='large' validate-on-blur)
            v-form.mt-5(v-model='addDealValid' ref='form')
                v-menu(ref="menu" v-model="menu" :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto" ) 
                    template(v-slot:activator="{ on, attrs }")
                        v-text-field.no-border.height-48(v-model='date' :rules='dateRule' v-bind="attrs" v-on="on" prepend-icon='mdi-calendar' :readonly='isLoading' hide-details="auto", :label=`$t('EDITDEALDATE.EXPECTED_CLOSE_DATE')`,)
                    v-date-picker(v-model='date' @input='menu = false' no-title scrollable) 
        v-card-actions.px-2.py-4
            v-row
                v-col.pr-2(cols='6')
                    v-btn(:ripple='false' :class='isLoading ? "btn-disable-style" : ""' @click='onCancel' width='100%' height='40' :disabled='isLoading' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
                v-col.pl-2(cols='6')
                    v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed dark) 
                        v-progress-circular(v-if='isLoading' indeterminate size='20')
                        .t-white(v-else) {{$t('GENERAL.SAVE')}}
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>

    import Vue from 'vue';
    import OpportunityDataService from "@/services/OpportunityDataService";
    import errorDialog from "@/components/Dialog/errorDialog";
    import i18n from '/common/plugins/vue-i18n.js' 

    export default Vue.extend({
        props: {
            opptyData: {
                type: Object,
                required: true,
            },
            editDate: {
                type: String,
                required: true
            },
            shouldReopen: {
                type: Boolean,
                required: true
            }
        },
        components: {
            errorDialog,
        },
        data() {
            return {
                addDealValid: true,
                dateRule: [(v) => /^\d{4}-\d{2}-\d{2}$|null/.test(v) && !!v || i18n.t('RULE.RULE_DATE')],
                menu: false,
                errorDialog: false,
                errorMessage: '',
                isLoading: false,
                date:'',
            }
        },
        created(){
            this.date = this.editDate;
        },
        methods: {
            validateForm(){
                if(this.$refs.form.validate()){
                    return true;
                }
                else{
                    return false;
                }
            },
            async onConfirm() {
                if(this.validateForm()){
                    this.isLoading = true;
                    this.opptyData.expected_close_date = this.date;
                    let data = JSON.parse(JSON.stringify(this.opptyData));
                    delete data.owner;
                    let editData = {
                        closed_reason: data.closed_reason,                                    
                        expected_close_date: data.expected_close_date,                                    
                        id: data.id,                                    
                    }
                    await OpportunityDataService.editForecastview(editData)
                    .then(() => {
                        this.$emit('emitEditDealDate', true, this.date);
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    })
                    .finally(()=>{
                        this.isLoading = false;
                    })
                }
            },
            onCancel() {
                this.$emit('emitEditDealDate', false);
            },
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            getcolor: function(data) {
                this.colorQ= data	 
                return {
                    '--backgroundColor':this.colorQ,
                    '--color': this.colorQ		
                }
            },
        },
        watch: {
            shouldReopen: function(){
                if(this.shouldReopen){
                    this.date = this.editDate;
                }
            }
        }
    });
</script>