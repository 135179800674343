<template lang="pug">
    v-card.dealDialog
        v-card-title.d-flex.justify-center {{$t('ADD_DEAL.TITLE')}}
        v-card-text
            v-form(v-model='addDealValid' ref='form')  
                v-text-field.mb-4(v-model='addDeal.name' :label=`$t('ADD_DEAL.NAME')`, :rules='formRules.nameRule' required='required' large='large' validate-on-blur)
                v-combobox.no-border.height-48(v-model='addDeal.oppty_org' :items='organizationList' item-text='name' item-value="uqNumber" :loading="isLoading" :search-input.sync="search" prepend-icon='mdi-domain' hide-details="auto" :label=`$t('ADD_DEAL.ORG')` :rules='formRules.companyRule')
                    template(v-slot:append-outer)
                        div(v-if="isNew")
                            v-icon(color="red", size="25") mdi-new-box
                    template(v-slot:item="data")
                        div.ellipsis(v-if="data.item.id == 0")
                            span {{ data.item.name }}
                            v-icon(color="red", size="25") mdi-new-box
                        div.ellipsis(v-else)
                            span {{ data.item.name }}
                v-combobox.no-border.height-48(v-if="!isNewContact" v-model="addDeal.primary_contact" :items="contactList" :search-input.sync="contactSearch" :menu-props="{openOnClick: true, closeOnClick: true}" :no-data-text=`$t('ADD_DEAL.NO_CONTACT')` @blur='contactList = []' item-text="searchStr" item-value="id" hide-details="auto" prepend-icon='mdi-account-multiple' :label=`$t('ADD_DEAL.CONTACT')` :loading='contact_loading')
                    template(v-slot:prepend-item)
                        v-list-item
                            v-btn(width="120" height="32" color="blue" plain border-0 @click="isNewContact = true")
                                v-icon.mr-1(size="14") icon-add
                                span {{$t('ADD_DEAL.NEW_CONTACT')}}
                    template(v-slot:item='data')
                        v-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
                            v-icon(v-if="data.item.image == null") mdi-account
                            img(v-else :src='data.item.image')
                        .t-black {{ data.item.full_name }}
                        .t-black.ml-2(v-if='data.item.org_name') {{ data.item.org_name }}
                    template(v-slot:selection='data')
                        .t-black.ellipsis(:title='data.item.org_name ? data.item.full_name + " " + data.item.org_name:data.item.full_name') {{ data.item.full_name }}
                v-row(no-gutters="no-gutters")
                    v-col(cols="6")
                        v-text-field.ml-3(v-if="isNewContact" v-model="newContact.last_name" :label= `$t('ADD_CONTACT.LAST_NAME')` large="large" :rules='formRules.contactRules')
                    v-col(cols="6")
                        v-text-field.ml-3(v-if="isNewContact" v-model="newContact.first_name" :label=`$t('ADD_CONTACT.FIRST_NAME')` large="large" :rules='formRules.contentLenRule50')  
                    v-col(cols="6")
                        v-btn.w-100.justify-start.px-0(v-if="isNewContact" text color="blue" plain border-0 @click="isNewContact = false")
                            v-icon(size="20") mdi-keyboard-return 
                            span.ml-4 {{$t('ADD_DEAL.OLD_CONTACT')}}
                v-text-field.no-border.height-48(v-model='addDeal.amount' prepend-icon='icon-chatmoney' :label=`$t('ADD_DEAL.AMOUNT')`, :rules='formRules.amountRule'  :ref="'editAmount'" v-show='amountShow|!amountValidate' @blur='showFormatAmount')
                v-text-field.no-border.height-48(v-model='formatAmount' prepend-icon='icon-chatmoney' :label=`$t('ADD_DEAL.AMOUNT')`,  @click='showEditAmount' @blur='showEditAmount' :readonly='true' v-show='!amountShow&&amountValidate')
                v-menu(ref="menu" v-model="menu" :z-index='1000' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto" ) 
                    template(v-slot:activator="{ on, attrs }")
                        v-text-field.no-border.height-48(v-model='addDeal.expected_close_date'  v-bind="attrs" v-on="on" prepend-icon='mdi-calendar'  hide-details="auto" :label= `$t('ADD_DEAL.EXPECTED_CLOSE_DATE')` :rules='formRules.dateRule')
                    v-date-picker(v-model='addDeal.expected_close_date' @input='menu = false' no-title scrollable) 
                v-select#forecast_category.no-border.height-48(v-model='addDeal.forecast_category' solo :items='forecast_category_List' prepend-icon='mdi-crystal-ball' item-text='name' item-value='key' :label=`$t('ADD_DEAL.FORCAST')`, )
                v-combobox.no-border.my-0.py-0.mt-2(:label=`$t('ADD_DEAL.PROCESS')` v-model='addDeal.stage_type' :items='stageList' item-text='stage_type_name' @change='selectStageType()' prepend-icon='mdi-sync' hide-details="auto" flat required='required' validate-on-blur :rules='formRules.stageTypeRule')
                div(style='height: 40px;')
                    .my-2.d-flex.justify-center.align-center.w-100.h-100(v-if='!isProcess && addDeal.stage_type != ""')
                        v-progress-circular(indeterminate size='20')
                    v-stepper.process-box.my-2(v-if='isProcess && process.length > 0' v-model='currentStage')
                        v-stepper-header.process-bar
                            template(v-for='item in process')
                                v-stepper-step(:key='`${item.sequence_number}`' :complete='currentStage>item.sequence_number' :step='item.sequence_number' :class='`customcolor`' :style='getcolor(item.stage_color)' edit-icon='icon-check' editable)
                                    span {{ item.stage_name }}
                            v-btn(v-if='lenProcess > 3 && process[0].sequence_number != 1' absolute style="marginTop: 4px; zIndex:1000;" class="mx-1" fab dark x-small color="white" @click='preStage()')
                                v-icon(color="primary") icon-arrow-left
                            v-btn(v-if='lenProcess > 3 && process[2].sequence_number != lenProcess' absolute style="marginLeft: 93.5%; marginTop: 4px; zIndex:1000;"  fab dark x-small color="white" @click='nextStage()')
                                v-icon(color="primary") icon-arrow-right
                    v-stepper.process-box.my-2(v-if='isProcess && process.length == 0 || addDeal.stage_type == ""')
                        v-stepper-header.process-bar.lightGrey.rounded-pill.d-flex.justify-center.align-center
                            span(style='color: rgba(0, 0, 0, 0.38);') {{$('ADD_DEAL.DEAL_PROCESS')}}
                v-window
                    customFieldRequired(v-if='isProcess' ref='CFRequired' :editData='JSON.stringify(this.addDeal.custom_field)' DataClass = 'Opportunity'  :StageTypeId='passId' :stageList='stageList').mt-5       
                    
        v-card-actions.px-2.py-4
            v-row
                v-col.pr-2(cols='6')
                    v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
                v-col.pl-2(cols='6')
                    v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed dark)
                        v-progress-circular(v-if='addBtn_loading' indeterminate size='20')
                        .t-white(v-else) {{$t('GENERAL.ADD')}}
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
    import Vue from 'vue';
    import ContactDataService from "@/services/ContactDataService";
    import OrganizationDataService from "@/services/OrganizationDataService";
    import StageDataService from "@/services/StageDataService";
    import OpportunityDataService from "@/services/OpportunityDataService";
    import forecast_category_List from "@/array/the_forecastCategoryList";
    import errorDialog from "@/components/Dialog/errorDialog";
    import i18n from '/common/plugins/vue-i18n.js';
    import selectContactField from '@/components/Common/selectContactField';
    import customFieldRequired from "@/components/Dialog/customFieldRequired";

    export default Vue.extend({
        props: {
            addDeal: {
                type: Object,
                required: true,
            },
        },
        components: {
            errorDialog,
            selectContactField,
            customFieldRequired,
        },
        async created(){    
            await StageDataService.stageList("deal")
                .then(response => {
                    this.stageList = response.data;
                    this.currentStage = 1;
                    if(typeof(this.addDeal.stage_type) == 'number' ){
                        this.stageList = this.stageList.filter(s =>s.id == this.addDeal.stage_type);
                        this.addDeal.stage_type = this.stageList[0];
                        this.addDeal.stage_type.stagedef_set.sequence_number = this.stageList[0].stagedef_set[0].sequence_number;
                    } 
                })
                .finally(() => (this.isLoading = false));
            if (this.addDeal.stage_type != null) 
            {
                this.allProcess = this.stageList.filter(s => s.id == this.addDeal.stage_type.id)[0].stagedef_set;
                this.passId = this.addDeal.stage_type.id; 
                if (this.addDeal.current_stage == undefined)
                {
                    if (this.addDeal.stage_type.stagedef_set == undefined) 
                    {
                        this.currentStage = 1;
                    } 
                    else 
                    {
                        this.currentStage = this.addDeal.stage_type.stagedef_set.sequence_number;
                    }
                } 
                else 
                {
                    this.currentStage = this.addDeal.current_stage;
                }
                this.selectStageType('new');
            }
            else
            {
                let default_process = null;
                try 
                {
                    default_process = this.user.default_value.default_process;
                } 
                catch (error) 
                {
                    default_process = null;
                }

                if(default_process == null || default_process.opportunity == null){
                    this.stageList.forEach(e => {
                        e.is_admin_default == true ? this.addDeal.stage_type = e : '';
                    })
                }
                else
                {
                    this.stageList.forEach(e => {
                        e.id == default_process.opportunity ? this.addDeal.stage_type = e : '';
                    })
                }
                this.currentStage = 1;
                this.selectStageType();
            }
        },
        data() {
            return {
                amountShow:false,
                isLoading: false,
                addDealValid: true,
                formRules: {
                    nameRule: [
                        (v) => !!v || i18n.t('RULE.RULE_R_OPPTY_NAME'),
                        (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
                    ],
                    amountRule: [(v) => /^\d{1,17}(\.\d{0,2})?$|(^$)|null/.test(v) && !!v || i18n.t('RULE.RULE_R_NUM_2')],
                    dateRule: [],
                    stageTypeRule: [(v) => !!v || i18n.t('RULE.RULE_PROCCESS')],
                    forecastRule: [(v) => !!v || i18n.t('RULE.RULE_FORECAST')],
                    companyRule: [
                        (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
                        (v) => /^(?!\s*$).+|(^$)|null/.test(v) || i18n.t('RULE.RULE_NULL'),
                    ],
                    contactRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R_LASTNAME'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50'),
                    ],
                    contentLenRule50: [(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
                },
                allProcess: [],
                process: [],
                contactList: [],
                organizationList: [],
                forecast_category_List: forecast_category_List,
                stageList: [],
                comboText: '',
                search: null,
                menu: false,
                isNewContact: false,
                newContact: {
                    last_name: '',
                    first_name: '',
                },
                errorDialog: false,
                errorMessage: '',
                isProcess: false,
                isContact: true,
                currentStage: 1,
                lenProcess: '',
                formatAmount:null,
                amountValidate:false,
                style: '',
                addBtn_loading: false,
                contactSearch: null,
                contact_loading: false,
                user: JSON.parse(window.localStorage.getItem('user')),
                passId:'',
                cfValid:true,
            }
        },
        methods: {       
            showFormatAmount(){
                this.amountShow = false;
                this.formatAmount = this.getFormatAmount(this.addDeal.amount);
                this.amountValidate = this.$refs.editAmount.validate();
            },
            showEditAmount(){
                this.amountShow = true;
                this.$refs.editAmount.focus();             
            },
            validateForm(){
                if(this.$refs.form.validate())
                {
                    return true;
                }
                else
                {
                    return false;
                }
            },
            getResponsedata(){
                this.$refs.CFRequired.emitData();
                if(this.$refs.CFRequired.responsedata === false)
                {
                    this.$refs.CFRequired.responsedata = null;
                    this.cfValid = false;
                    return;
                }
                this.addDeal.custom_field = this.$refs.CFRequired.responsedata;
            },
            getProcessColor: function (groupIdx) {
                let color = '';
                switch (groupIdx) {
                    case 1:
                        color = 'primary';
                        break;
                    case 2:
                        color = 'orange';
                        break;
                    case 3:
                        color = 'green';
                        break;
                    case 4:
                        color = 'purple';
                        break;
                    case 5:
                        color = 'red';
                        break;
                    default:
                        color = 'gary';
                        break;
                }
                return color;
            },
            async onConfirm() {
                this.formRules.dateRule.push((v) => /^\d{4}-\d{2}-\d{2}$|null/.test(v) && !!v || i18n.t('RULE.RULE_DATE'));
                if (await this.validateForm() && !this.addBtn_loading) 
                {
                    if((this.addDeal.custom_field != null && this.addDeal.custom_field !== undefined) || this.$refs.CFRequired.process_array.length != 0)
                        this.getResponsedata();
                    if(this.cfValid == false)
                    {
                        this.cfValid = true;
                        return;
                    }
                    if (typeof(this.addDeal.stage_type) !== 'object') 
                    {
                        this.showErrorDialog(i18n.t('ADD_DEAL.ERROR'));
                        return;
                    }
                    
                    let oppty_org = this.addDeal.oppty_org;
                    let oppty_contact = this.addDeal.primary_contact;
                    let new_contact_lastName = this.newContact.last_name;

                    if (this.comboText == '') 
                    {
                        oppty_org = {};
                    } 
                    else if (typeof(oppty_org) == 'string') 
                    {
                        oppty_org = {
                            id: -1,
                            name: oppty_org,
                            company_number: '',
                        }
                    }

                    if (this.isNewContact == true) 
                    {
                        if (new_contact_lastName == '') 
                        {
                            this.isContact = false;
                            oppty_contact = {};
                        }
                        else 
                        {
                            this.isContact = true;
                            oppty_contact = {
                                id: 0,
                                last_name: this.newContact.last_name,
                                first_name: this.newContact.first_name != null ? this.newContact.first_name : "",
                            }                       
                        }
                    }
                    else 
                    {
                        if (oppty_contact == '' || oppty_contact == null) 
                        {
                            this.isContact = false;
                            oppty_contact = {};
                        }
                        else if (typeof(oppty_contact) === 'string') 
                        {
                            this.showErrorDialog(i18n.t('ADD_DEAL.ERROR_2'));
                            return;
                        }
                        else 
                        {
                            this.isContact = true;
                        }
                    }

                    if (!this.isContact && this.comboText == '') 
                    {
                        this.showErrorDialog(i18n.t('ADD_DEAL.ERROR_3'));
                        this.isContact = true;
                        return;
                    }
                    else
                    {
                        this.addBtn_loading = true;
                    }
                    let addDealData = {};
                    addDealData = {
                        name: this.addDeal.name,
                        primary_contact: this.isContact ? oppty_contact : {},
                        oppty_org: oppty_org != "" && oppty_org != null ? oppty_org : {},
                        oppty_contact: null,
                        stage_type: this.addDeal.stage_type.id,
                        current_stage: this.allProcess[this.currentStage-1],
                        amount: this.addDeal.amount,
                        expected_close_date: this.addDeal.expected_close_date,
                        forecast_category: this.addDeal.forecast_category,
                        custom_field: this.addDeal.custom_field? this.addDeal.custom_field:null,
                    }

                    await OpportunityDataService.quickCreate(addDealData)
                    .then(response => {
                        let newDeal = response.data;
                        this.addDeal.id = response.data.id;
                        if(this.addDeal.oppty_org != null)
                        {
                            if (this.addDeal.oppty_org.id == 0) 
                            {
                                if (response.data.oppty_org != null) 
                                {
                                    let principalData = {
                                        "organization_id": response.data.oppty_org.id,
                                        "owner_id": response.data.owner.id,
                                    };
                                    ContactDataService.createCompanyPrincipal(principalData);
                                }
                            }
                        }
                        this.addBtn_loading = false;
                        this.$emit('emitAddDealDialog', true);
                        this.$emit('emitNewDealData', newDeal);
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    });
                }
            },
            onCancel() {
                this.$emit('emitAddDealDialog', false);
            },
            async selectStageType(type) {
                try 
                {
                    if (this.addDeal.stage_type.stagedef_set.length != undefined) 
                    {
                        this.allProcess = this.addDeal.stage_type.stagedef_set;
                        this.passId = this.addDeal.stage_type.id;
                    }
                    this.allProcess.sort((a, b) => {
                        return a.sequence_number - b.sequence_number;
                    });
                    this.isProcess = true;
                    this.lenProcess = this.allProcess.length;
                    await this.setProcess();
                    await (this.currentStage = this.currentStage - 1);
                    if(type === undefined)
                        this.currentStage = 1;
                } 
                catch 
                {
                    this.isProcess = false;
                    return;
                }
            },
            setProcess() {
                if (this.lenProcess > 3) 
                {
                    if (this.currentStage <  3) 
                    {
                        this.process = this.allProcess.slice(0, 3);
                    } 
                    else if (this.currentStage > this.lenProcess - 2) 
                    {
                        this.process = this.allProcess.slice(this.lenProcess - 3, this.lenProcess);
                    } 
                    else 
                    {
                        this.process = this.allProcess.slice(this.currentStage - 2, this.currentStage + 1);
                    }
                }
                else 
                {
                    this.process = this.allProcess;
                }
                this.currentStage = this.currentStage + 1;
            },
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            getcolor: function(data) {
                this.colorQ= data	 
                return {
                    '--backgroundColor':this.colorQ,
                    '--color': this.colorQ,	
                }
            },
            async nextStage() {
                if (this.process[2].sequence_number == this.lenProcess) 
                {
                    return;
                } 
                else 
                {
                    let n = this.process[2].sequence_number;
                    n = n + 2;
                    await this.changeAddStage(n);
                    await (this.currentStage = this.currentStage - 1);
                }
            },
            async preStage() {
                if (this.process[0].sequence_number == 1) 
                {
                    return;
                } 
                else 
                {
                    let n = this.process[0].sequence_number;
                    n = n - 2;
                    await this.changeMinusStage(n);
                    await (this.currentStage = this.currentStage - 1);
                }
            },
            changeAddStage(n) {
                if (n > this.lenProcess - 2) 
                {
                    this.process = this.allProcess.slice(this.lenProcess - 3, this.lenProcess);
                } 
                else 
                {
                    this.process = this.allProcess.slice(n - 2, n + 1);
                }
                this.currentStage = this.currentStage + 1;
            },
            changeMinusStage(n) {
                if (n < 3) 
                {
                    this.process = this.allProcess.slice(0, 3);
                } 
                else 
                {
                    this.process = this.allProcess.slice(n - 2, n + 1);
                }
                this.currentStage = this.currentStage + 1;
            }
        },
        computed: {
            isNew: function(){
                if(this.addDeal.oppty_org == "" || this.addDeal.oppty_org == null || this.addDeal.oppty_org == " ")
                {
                    return false;
                }
                if(typeof (this.addDeal.oppty_org) === 'object')
                {
                    if(this.addDeal.oppty_org.id == 0)
                    {
                        return true;
                    }
                    else
                    {
                        return false;
                    }
                }
                else
                {
                    return true;
                }
            },
        },
        watch: {
            async search(val) {
                var re = /^(?!\s*$).+/;
                var check = re.test(val);
                var checkLength = /^.{0,200}$/.test(val);
                this.comboText = "";
                if(check && val != null && checkLength)
                {
                    let charNum = val.split(/[\u4e00-\u9fa5_a-zA-Z0-9\s]/).length -1;
                    this.comboText = val;
                    if (!this.isLoading && charNum>=2 && charNum==val.length ) 
                    {
                        this.isLoading = true;
                        await OrganizationDataService.findOrganizationByNamefromDB(val)
                            .then(response => { 
                                this.organizationList = response.data; 
                                let num = 0;
                                this.organizationList.forEach((item)=>{
                                    item.uqNumber = num;
                                    num ++;
                                })
                                })
                            .finally(() => {
                                this.isLoading = false;
                            });
                    }
                    else 
                    {
                        this.isLoading = false;
                    }
                }
            },
            contactSearch(val) {

                // check user input is en / zh / num
                let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
                let isEnZhNum = false;
                pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

                // check user input data type
                let isString; 
                typeof(val) == 'string' ? isString = true : isString = false;
                let array = [];

                // search
                if(isEnZhNum && !!val && isString)
                {
                    this.contact_loading = true;
                    ContactDataService.getAll(1,val,100,[])
                    .then(response => {
                        response.data.list.forEach(item => {
                            item.searchStr = item.full_name 
                                            +item.full_name_en
                                            +item.full_name_en.toLowerCase()
                                            +item.full_name_en.toUpperCase()
                                            +item.full_name_zh;
                            if(item.searchStr.includes(val))
                            {
                                if(item.organization_set.length > 0)
                                {
                                    item.org_name = "[ "+item.organization_set[0].name +" ]";
                                }
                                array.push(item);
                            }
                        })
                        this.contactList = array;
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    })
                    .finally(()=>{
                        this.contact_loading = false;
                    })
                }
                else
                {
                    this.contactList = [];
                }
            }
        },
    });
</script>