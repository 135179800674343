<template lang="pug">
    v-card.lose-reason-dialog
        v-card-title.d-flex.justify-center(v-if='processData.case_id != undefined') {{$t('CASE_DETAIL.CHANGE_STAGE')}}
        v-card-title.d-flex.justify-center(v-else) {{$t('DEAL_DETAIL.CHANGE_STAGE')}}
        v-card-text(style="height:80px")
            v-select.m-4(v-model='process' :items='processList' item-text='stage_type_name' item-value='id' return-object rounded solo prepend-inner-icon='mdi mdi-chart-arc')
        v-card-actions.px-2.py-4
            v-row
                v-col.pr-2(cols='6')
                    v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
                v-col.pl-2(cols='6')
                    v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed dark) {{$t('GENERAL.SAVE')}}
</template>

<script>
	import Vue from 'vue';
    import StageDataService from "@/services/StageDataService";
    import OpportunityDataService from "@/services/OpportunityDataService";
    import CaseDataService from "@/services/CaseDataService";

	export default Vue.extend({
        props: {
            processData: {
                type: Object,
                required: true,
            },
        },
        data() {
			return {
                process: {},
                processList: [],
                updateProcess: {},
			}
		},
        created(){
            this.process = this.processData.stage_type;

            if (this.processData.case_id != undefined) {
                StageDataService.stageList("case")
                .then(response => {
                    this.processList = response.data;
                });
            } else {
                StageDataService.stageList("deal")
                .then(response => {
                    this.processList = response.data;
                });
            }
        },
        methods: {
			onConfirm() {
                if (this.processData.stage_type.id != this.process.id) {
                    if (this.processData.case_id != undefined) {
                        let updateData = {
                            "id": this.processData.case_id,
                            "subject": this.processData.subject,
                            "stage_type": this.process,
                            "current_stage": this.process.stagedef_set[0].id
                        }
                        CaseDataService.editdetail(updateData)
                        .then(response => {
                            this.$emit('emitChangeProcessDialog', true);
                            this.$emit('emitChangeProcessData', response.data);
                        });
                    } else {
                        let updateData = {
                            "id": this.processData.id,
                            "name": this.processData.name,
                            "stage_type": this.process,
                            "current_stage": this.process.stagedef_set[0].id
                        }
                        OpportunityDataService.editOpptyDetail(updateData)
                        .then(response => {
                            this.$emit('emitChangeProcessDialog', true);
                            this.$emit('emitChangeProcessData', response.data);
                        });
                    }
                }
            //     this.$emit('emitChangeProcessDialog', true);
            //     this.$emit('emitChangeProcessData', this.updateProcess);
			},
			onCancel() {
				this.$emit('emitChangeProcessDialog', false);
			},
		}
	});
</script>