<template lang="pug">
    v-card.lose-reason-dialog
        v-card-title.d-flex.justify-center(v-if='loseDeal.case_id != undefined') {{$t('LOSEREASON.TITLE')}}
        v-card-title.d-flex.justify-center(v-if='!winDeal && loseDeal.case_id == undefined') {{$t('LOSEREASON.TITLE_2')}}
        v-card-title.d-flex.justify-center(v-if='winDeal && loseDeal.case_id == undefined') {{$t('LOSEREASON.TITLE_3')}}
        //- close case
        v-card-text(v-if='loseDeal.childCases == null && loseDeal.case_id != undefined')
            v-text-field.no-pointer-event(v-model='loseDeal.subject' :label=`$t('LOSEREASON.CASE_NAME')`, required='required' large='large' validate-on-blur)
            v-text-field.no-border.height-48.no-pointer-event(v-model='loseDeal.primary_contact.__chineseName__ || loseDeal.primary_contact.name' readonly prepend-icon='mdi-account-multiple' ,:label=`$t('LOSEREASON.CONTACT_NAME')` )
            v-text-field.no-border.height-48(v-model='loseDeal.closed_date' readonly prepend-icon='icon-calendar' label='結案日期', :label=`$t('LOSEREASON.CLOSE_DATE')` )
            v-form(v-model='valid' ref='form')
                v-select.no-border(v-model="reason" :items="loseReasonList" item-text="name" required item-value="key" :rules='loseReasonRule' ,:label=`$t('LOSEREASON.CLOSE_REASON')` )
        //- close case with child cases
        v-card-text(v-else-if='loseDeal.childCases != null && loseDeal.case_id != undefined')
            .data-area
            v-form(v-model='valid' ref='form')
                v-data-table.border-table(:headers="tableHeader" :items="closeCaseList" :items-per-page="loseDealPerPage" :page.sync="loseDealPage" @page-count="loseDealPageCount = $event" hide-default-footer="hide-default-footer")
                    template(v-slot:item.subject="{ item }")
                        .ellipsis {{ item.subject }}
                    template(v-slot:item.primary_contact.__chineseName__="{ item }")
                        .ellipsis {{ item.primary_contact.__chineseName__ }}
                    template(v-slot:item.closed_date="{ item }") {{ item.closed_date }}
                    template(v-slot:item.closed_reason="{ item }")
                        .ellipsis
                            v-select.no-border(v-if='item.status == "CLOSED"' readonly v-model="item.closed_reason" :items="loseReasonList" item-text="name" required item-value="key" :rules='loseReasonRule')
                            v-select.no-border(v-else v-model="item.closed_reason" :items="loseReasonList" item-text="name" required item-value="key" :rules='loseReasonRule')
                    template(v-slot:item.active="{ item }")
                        .ellipsis
                        v-chip(v-if='item.status == "CLOSED"' color='primary' text-color='white')  {{$t('LOSEREASON.CLOSED')}}
                        v-chip(v-else text-color='black')  {{$t('LOSEREASON.OPEN')}}
            v-pagination.mt-3(v-if='loseDealPageCount > 1' v-model="loseDealPage", :length="loseDealPageCount" ,circle="circle" :total-visible="10")
        //- lose deal
        v-card-text(v-else)
            v-text-field.no-pointer-event(v-model='loseDeal.name' ,:label=`$t('LOSEREASON.OPPTY_NAME')` required='required' large='large' validate-on-blur)
            v-menu(ref="menu" v-model="menu" :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                template(v-slot:activator="{ on, attrs }")
                    v-text-field.no-border.height-48(v-model='loseDeal.closed_date' :rules='dateRule' v-bind="attrs" v-on="on" prepend-icon='mdi-calendar' hide-details="auto" ,:label=`$t('LOSEREASON.CLOSE_DATE')` )
                v-date-picker(v-model='loseDeal.closed_date' @input='menu = false' no-title scrollable)
            v-form(v-model='valid' ref='form')
                v-text-field.no-border.height-48(v-model='loseDeal.amount' prepend-icon='icon-chatmoney' ,:label=`$t('LOSEREASON.AMOUNT')`  :rules='amountRule' v-show='amountShow|!amountValidate' :ref="'editAmount'" @blur='showFormatAmount("amount")')
                v-text-field.no-border.height-48(v-model='formatAmount' prepend-icon='icon-chatmoney' ,:label=`$t('LOSEREASON.AMOUNT')` @click='showEditAmount("amount")' @blur='showEditAmount("amount")' :readonly='true' v-show='!amountShow|!amountShow&&amountValidate')
            
                v-text-field.no-border.height-48.no-pointer-event(v-model='loseDeal.oppty_org != null ? loseDeal.oppty_org.name : loseDeal.oppty_contact.__chineseName__' readonly prepend-icon='mdi-account-multiple' ,:label=`$t('LOSEREASON.CUSTOMER_NAME')` )
                
                v-select.no-border(v-model="reason" :items="loseReasonList" item-text="name" required item-value="key" :rules='loseReasonRule' ,:label=`$t('LOSEREASON.LOSE')` v-if='!winDeal' )
                v-select.no-border(v-model="reason" :items="loseReasonList" item-text="name" required item-value="key" :rules='loseReasonRule' readonly ,:label=`$t('LOSEREASON.WIN')` v-if='winDeal')
        v-card-actions.px-2.py-4
            v-row
                v-col.pr-2(cols='6')
                    v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
                v-col.pl-2(cols='6')
                    v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed dark) {{$t('GENERAL.SAVE')}}
</template>

<script>
    import Vue from 'vue';
    import get_oppty_closed_reason_func from "@/array/the_closeReasonList";
    import closeCaseReasonList from "@/array/the_closeCaseReasonList";
    import OpportunityDataService from "@/services/OpportunityDataService";
    import CaseDataService from '@/services/CaseDataService';
    import i18n from '/common/plugins/vue-i18n.js' 

    export default Vue.extend({
        props: {
            loseDeal: {
                type: Object,
                required: true,
            },
            // shouldReopen: {
            //     type: Boolean,
            //     required: true,
            // }
        },        
        data() {
			return {
                tableHeader: [
                    {
                        text: i18n.t('LOSEREASON.CASE_NAME'),
                        value: "subject",
                        align: "center",
                        // width: "15%",
                        sortable: false
                    },
                    {
                        text: i18n.t('LOSEREASON.CONTACT_NAME'),
                        value: "primary_contact.__chineseName__",
                        align: "center",
                        // width: "17%",
                        sortable: false
                    },
                    {
                        text: i18n.t('LOSEREASON.CLOSE_DATE'),
                        value: "closed_date",
                        align: "center",
                        // width: "19%",
                        sortable: false
                    },
                    {
                        text: i18n.t('LOSEREASON.CLOSE_REASON'),
                        value: "closed_reason",
                        align: "center",
                        width: "27%",
                        sortable: false
                    },
                    {
                        text: i18n.t('LOSEREASON.STATUS'),
                        value: "active",
                        align: "center",
                        width: "15%",
                        sortable: false
                    },                    
                ],
                loseDealPage: 1,
                loseDealPageCount: 0,
                loseDealPerPage: 5,
                closeCaseList: [],                
                closeReasonList: [],
                closeCaseReasonList: closeCaseReasonList,
                loseReasonList: [],
                reason: '',
                valid: false,
                loseReasonRule: [(v) => !!v || i18n.t('RULE.RULE_REASON')],
                dateRule: [(v) => /^\d{4}-\d{2}-\d{2}$|null/.test(v) && !!v || i18n.t('RULE.RULE_DATE')],
                amountRule: [(v) => /^\d{1,17}(\.\d{0,2})?$/.test(v) || i18n.t('RULE.RULE_R_NUM_2')],
                menu: '',
                winDeal:false,
                closeDialog:false,
                amountShow: false,
                amountValidate: true,  
                formatAmount: null,
                calcelAuoumt:null
			}
		},
        created(){
            this.getCloseReasonList();
        },
        methods: {
            async getCloseReasonList(){
                this.closeReasonList = await get_oppty_closed_reason_func();
                window.addEventListener('keyup', this.close);
                // case page
                if (this.loseDeal.case_id != undefined) {
                    this.loseReasonList = this.closeCaseReasonList;

                    this.closeCaseList.push(this.loseDeal)
                    if(this.loseDeal.childCases != null) {
                        this.loseDeal.childCases.forEach(childcase => {
                            this.closeCaseList.push(childcase)
                        })
                    }
                    this.closeCaseList.forEach(item => {
                        if(item.closed_date == undefined) {
                            item.closed_date = this.getDate();
                        }
                    })                    
                } 
                // deal page
                else {
                    // if won deal
                    if(this.loseDeal.closed_reason == 1)
                    {
                        this.winDeal = true;
                        this.loseReasonList = this.closeReasonList.filter(item => {
                        if (item.key == 1) {
                            return  true;
                        } else {
                            return false;
                        }
                    });
                        this.loseDeal.closed_date = this.loseDeal.expected_close_date;
                        this.reason = 1
                    }
                    // if lose deal
                    else
                    {
                        this.loseReasonList = this.closeReasonList.filter(item => {
                            if (item.key == 0 || item.key == 1) {
                                return false;
                            } else {
                                if (item.selected)
                                    return true;
                                else
                                    return false
                            }
                        });
                        this.loseDeal.closed_date = this.loseDeal.expected_close_date;
                    }    
                    this.formatAmount = this.getFormatAmount(this.loseDeal.amount);
                    this.calcelAuoumt = JSON.parse(JSON.stringify(this.loseDeal.amount));
                }
            },
            onConfirm() {
                if(this.$refs.form.validate()){
                    if (this.closeCaseList.length > 0) {
                    this.closeCaseList.forEach(item => {
                        if (item.case_id != undefined || item.id != undefined && item.status != 'CLOSED') {
                            let data = {
                                id: item.case_id || item.id,
                                subject: item.subject,
                                closed_reason: item.closed_reason || this.reason,
                                closed_date: item.closed_date
                            }
                            CaseDataService.editdetail(data)
                            .then(response => {
                                if(this.closeCaseList.indexOf(item) ==  0) {
                                    this.$emit('emitCloseReasonData', response.data);
                                    this.$emit('emitLoseReasonDialog', true);
                                    this.reason = '';          
                                }
                            })
                        }
                    })
                    } else {
                        let data = {
                            closed_date: this.loseDeal.closed_date,
                            closed_reason: this.reason,
                            amount: this.loseDeal.amount
                        }
                        OpportunityDataService.opptySummaryUpdate(this.loseDeal.id, data)
                        .then(response => {
                            this.loseDeal.closed_reason = response.data.closed_reason;
                            this.$emit('emitLoseReasonDialog', true);
                        });
                    }
                }
            },
            onCancel() {    
                this.loseDeal.amount = this.calcelAuoumt
                this.$emit('emitLoseReasonDialog', false);                
            },
            getDate(){
                var fullDate = new Date();
                var yyyy = fullDate.getFullYear();
                var MM = (fullDate.getMonth() + 1) >= 10 ? (fullDate.getMonth() + 1) : ("0" + (fullDate.getMonth() + 1));
                var dd = fullDate.getDate() < 10 ? ("0"+fullDate.getDate()) : fullDate.getDate();
                var today = yyyy + "-" + MM + "-" + dd;
                return today;
            },      
            close(ev){
                if(ev.key == "Escape")
                {
                    this.onCancel()
                }                
            },
            showFormatAmount(data){
                if(data==='amount'){
                    this.amountShow = false
                    this.formatAmount = this.getFormatAmount(this.loseDeal.amount)
                    this.amountValidate = this.$refs.editAmount.validate()
                }				
            },
            showEditAmount(data){
                if( data==='amount')
                {
                    this.amountShow = true
                    this.$refs.editAmount.focus()
                }                            
            },      
        },
        watch: {
            reopenDialog: function(){
                if(this.reopenDialog){
                    this.getCloseReasonList();
                }else{
                    this.winDeal = false;
                }
            },
        }        
    });
</script>